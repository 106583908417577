.d-flex {
  display: flex;

  &.-gap {
    gap: 0;
  }

  &.-gap-1 {
    gap: .45rem;
  }

  &.-gap-2 {
    gap: 1rem;
  }

  &.-row {
    flex-direction: row;
    align-items: center;
  }

  &.-reverse-row {
    flex-direction: row-reverse;
  }

  &.-reverse-column {
    flex-direction: column-reverse;
  }

  &.-column {
    flex-direction: column;
  }

  &.-space-between {
    justify-content: space-between;
    gap: 1rem;
  }

  &.-justify-center {
    justify-content: center;
  }

  &.-align-items-center {
    align-items: center;
  }
}

.p-0 {
  padding: 0;
}

.w-100 {
  //width: 100%;
}


