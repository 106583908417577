@import "./shared/stylesheets/ionic-overrides";
@import "./shared/stylesheets/layout";
@import "./shared/stylesheets/common";

.firebase-emulator-warning {
  display: none;
}

.render {
  transition: opacity 0s;

  &.-hidden {
    opacity: 0;
  }

  .render-details {
    margin: .5rem 0 0;

    .render-name {
      font-size: 1rem;
      font-weight: 600;
      margin: 0 0 .1rem;
    }

    .render-description {
      margin: 0;
      font-size: .9rem;
      line-height: 1.25;
      font-weight: 400;
      color: $gray;
    }
  }

  .render-thumbnail {
    min-height: 100px;
    display: flex;
    flex-shrink: 1;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: $render-shadow;
    transition: box-shadow .22s;

    img {
      width: 100%;
      height: 100%;
      flex-shrink: 1;
    }
  }
}

.renders-grid {
  display: flex;
  margin-left: -$renders-grid-gutter-size; // gutter size offset
}

.renders-column {
  padding-left: $renders-grid-gutter-size; // gutter size
  background-clip: padding-box;

  .render {
    margin-bottom: 1.15rem;
    animation: entryAnimation .5s calc(var(--entry-index) * 85ms) both ease-in;
  }
}

// LAYOUT PRIMITIVES
.back-button {
  height: 3.2rem;
  width: 3.2rem;
  margin-bottom: .47rem;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: $z-overlay-content;
  box-shadow: 0 4px 15px rgba(234, 234, 234, 0.15);
  filter: blur(0.5px);
  border-radius: 50%;

  @media (min-width: $sm-phone) and (max-width: $md-phone) {
    height: 2.8rem;
    width: 2.8rem;
    margin-bottom: .4rem;
  }

  @media (max-width: $sm-phone) {
    height: 2.66rem;
    width: 2.66rem;
    margin-bottom: .45rem;
  }

  ion-icon {
    font-size: 1.7rem;
    color: #000000;

    @media (max-width: $sm-phone) {
      font-size: 1.55rem;
    }
  }
}



// SPACING
.m-0 {
  margin: 0 !important;
}

.mt-1 {
  margin-top: .5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mb-1 {
  margin-top: .5rem;
}

.mb-2 {
  margin-top: 1rem;
}

.mb-3 {
  margin-top: 1.5rem;
}

.mb-4 {
  margin-top: 2rem;
}


// COLORS
.gray {
  color: $gray;
}

.gray-light {
  color: $gray-light;
}
