// Colors
$primary-color: #FF6700;
$secondary-color: #3B899F;
$gray: #818181;
$dim-gray: #6d6a75;
$gray-light: #e6e6e6;
$black-coffee: #37323e;
$dark: #4f4859;
$success-color: #10ac84;
$neutral-color: #2e86de;
$warning-color: #feca57;
$error-color: #ee5253;
$border-gray: #efefef;

// Device sizes
$sm-phone: 320px;
$md-phone: 375px;

// Font Weights
$w-black: 900;
$w-extra-bold: 800;
$w-bold: 700;
$w-semi-bold: 600;
$w-medium: 500;
$w-regular: 400;
$w-light: 300;

// Shadows
$image-shadow: 2px 6px 15px rgba(0, 0, 0, 0.2);
$render-details-image-shadow: 0 5.5px 2.2px -2px rgba(0, 0, 0, 0.006), 0 13.2px 5.3px -2px rgba(0, 0, 0, 0.008), 0 24.9px 10px -2px rgba(0, 0, 0, 0.01), 0 44.4px 17.9px -2px rgba(0, 0, 0, 0.012), 0 83.1px 33.4px -2px rgba(0, 0, 0, 0.014), 0 199px 80px -2px rgba(0, 0, 0, 0.02);
$render-shadow: 2px 5px 18px rgba(0, 0, 0, 0.1);
$content-shadow: 0 5px 20px rgba(0, 0, 0, 0.06);
$article-image-shadow: 0 5.5px 2.2px -2px rgba(0, 0, 0, 0.006), 0 13.2px 5.3px -2px rgba(0, 0, 0, 0.008), 0 24.9px 10px -2px rgba(0, 0, 0, 0.01), 0 44.4px 17.9px -2px rgba(0, 0, 0, 0.012), 0 83.1px 33.4px -2px rgba(0, 0, 0, 0.014), 0 199px 80px -2px rgba(0, 0, 0, 0.02);

// SPACING
$renders-grid-gutter-size: .8rem;

// Z Index
$z-modal: 1;
$z-modal-content: 2;
$z-overlay-content: 300;
