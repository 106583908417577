@import "src/shared/stylesheets/common";

.render-details-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  z-index: $z-overlay-content;

  .back-button {
    position: fixed;
    top: 2.65rem;
    left: 1.2rem;
    z-index: $z-modal;
  }

  .cover-image {
    max-height: 24rem;
    min-height: 4rem;
    overflow: hidden;
    display: grid;
    background: white;

    img {
      width: 100%;
      height: auto;
      box-shadow: $image-shadow;
    }
  }

  .render-details {
    background: white;
    overflow: hidden;
    padding: 0 0 2.5rem;

    .render-details-container {
      margin: 1rem 1.2rem 0;

      .render-name {
        font-size: 1.7rem;
        color: $secondary-color;
        font-weight: $w-extra-bold;
        margin: 0 0 5px;
      }

      .render-metadata {
        font-size: 1.12rem;
        color: $dim-gray;
        font-weight: $w-regular;
        margin: 0 0 2px;

        strong {
          font-weight: $w-semi-bold;
        }
      }

      .render-description {
        margin: .5rem 0;

        p {
          font-size: 1.25rem;
          color: $black-coffee;
          margin: 0 !important;
        }

        blockquote {
          margin: .55rem;
          padding: .3rem 0 .4rem .6rem;
          border-left: 6px solid $primary-color;

          p {
            color: $dim-gray;
            font-size: 1.35rem;
            margin: 0;
          }
        }

        img {
          border-radius: 12px;
          box-shadow: $render-details-image-shadow;
        }
      }

      .render-actions{
        margin: 0 0 1.5rem;
      }
    }

    .render-details-section {
      margin: 0 1rem 2rem;
      &.-compact{
        margin: 0 1rem 1rem;
      }
    }

    .section-label {
      display: flex;
      align-items: center;
      margin: 0 0 1rem;

      ion-icon {
        align-self: center;
        margin: 0 .35rem 0 0;
        font-size: 1.8rem;
        color: $dim-gray;
      }

      p {
        color: $black-coffee;
        font-size: 1.18rem;
        font-weight: $w-semi-bold;
        margin: 0;
      }
    }

    .article-gallery {
      overflow-x: auto;
      margin: 0 0 .4rem;

      .images {
        display: inline-flex;
        flex-direction: row;
        margin: 0 1.2rem 0 0;

        .image-container {
          width: 250px;
          height: 260px;
          display: flex;
          align-self: center;
          align-items: center;
          overflow: hidden;
          position: relative;
          border-radius: 8px;
          justify-content: center;
          margin: .1rem 0 1.2rem 1.2rem;
          box-shadow: $image-shadow;

          img {
            flex-shrink: 0;
            max-width: 100%;
            min-height: 100%;
          }
        }
      }
    }

    .render-gallery {
      .images{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.4rem;

        .image-container{
          img{
            border-radius: 4px;
            box-shadow: $image-shadow;
          }
        }
      }
    }

    .render-gallery::-webkit-scrollbar {
      display: none;
    }


    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: $z-modal-content;
      background: white;
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
      width: 100%;
      padding: .6rem .8rem;
      border-top: 1px solid #eeeeee;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      p {
        font-weight: $w-semi-bold;
        margin: 0;
        align-self: center;
        font-size: 1.42rem;
      }
    }
  }
}
